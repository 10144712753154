const getOperationSys = () => {
  const { platform } = navigator;

  let OS = '';
  const OSArray = {};
  const UserAgent = navigator.userAgent.toLowerCase();
  OSArray.Windows = platform === 'Win32' || platform === 'Windows';
  OSArray.Mac = platform === 'Mac68K' || platform === 'MacPPC' || platform === 'Macintosh' || platform === 'MacIntel';
  OSArray.iphone = UserAgent.indexOf('iPhone') > -1;
  OSArray.ipod = UserAgent.indexOf('iPod') > -1;
  OSArray.ipad = UserAgent.indexOf('iPad') > -1;
  OSArray.Android = UserAgent.indexOf('Android') > -1;

  for (const i in OSArray) {
    if (OSArray[i]) {
      OS = i;
    }
  }
  return OS;
};

/**
 * PC(windows）=1 PC(MacOS)=2 IOS=3 Android=4 其它=0
 * 不用switch case 代码格式化会报错
 * @returns {number}
 */

export const getSystem = () => {
  const opType = getOperationSys();
  if (opType === 'Windows') {
    return 1;
  }
  if (opType === 'Mac') {
    return 2;
  }
  if (opType === 'iphone' || opType === 'ipod' || opType === 'ipad') {
    return 3;
  }
  if (opType === 'Android') {
    return 4;
  }
  return 0;
};
