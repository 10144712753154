import anime from 'animejs';

export default {
  menuBgEnter() {
    anime({
      targets: '.menu-background',
      translateX: '-100%',
      easing: 'easeOutQuint',
      duration: 800,
    });
  },
  menuBgLeave() {
    anime({
      targets: 'div.menu-background',
      translateX: '0',
      easing: 'easeOutExpo',
      duration: 800,
    });
  },
  iconMenuEnter() {
    anime({
      targets: '.icon-menu',
      opacity: '1',
      easing: 'easeOutQuint',
      duration: 400,
      delay: 400,
    });
  },
  iconMenuLeave() {
    anime({
      targets: '.icon-menu',
      rotate: '-90deg',
      opacity: '0',
      easing: 'easeOutExpo',
      duration: 400,
    });
  },
  // page1AnimateEnter() {
  //   anime({
  //     targets: '.animate-item-1-1',
  //     translateX: '0',
  //     opacity: '1',
  //     easing: 'easeOutQuint',
  //     duration: 3000
  //   })
  //   anime({
  //     targets: '.animate-item-1-2',
  //     translateX: '0',
  //     opacity: '1',
  //     easing: 'easeOutQuint',
  //     duration: 3000
  //   })
  //   anime({
  //     targets: '.animate-item-1-3',
  //     translateX: '0',
  //     opacity: '1',
  //     easing: 'easeOutQuint',
  //     duration: 3000
  //   })
  // },
  // page1AnimateLeave() {
  //   anime({
  //     targets: '.animate-item-1-1',
  //     translateX: '30%',
  //     opacity: '0',
  //     easing: 'easeOutExpo',
  //     duration: 800
  //   })
  //   anime({
  //     targets: '.animate-item-1-2',
  //     translateX: '50%',
  //     opacity: '0',
  //     easing: 'easeOutExpo',
  //     duration: 800
  //   })
  //   anime({
  //     targets: '.animate-item-1-3',
  //     translateX: '70%',
  //     opacity: '0',
  //     easing: 'easeOutExpo',
  //     duration: 800
  //   })
  // }
};
