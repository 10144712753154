/*
 * @Author: ruiqiwu
 * @Date: 2022-07-18 15:58:10
 * @LastEditors: ruiqiwu
 * @LastEditTime: 2023-02-27 20:24:47
 * @FilePath: /cftxd/src/main.js
 * @Description:
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import jQuery from 'jquery';
import 'fullpage.js';
import './core';
import { aegisInstance } from '@/utils/aegis';
import { mtaInit } from '@/utils/mta/mta';

import '@/styles/reset.scss';
// 引入全局jquery
window.jQuery = jQuery;
window.$ = jQuery;

Vue.prototype.$aegis = aegisInstance;

Vue.config.productionTip = false;

mtaInit();

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
