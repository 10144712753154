import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/home/home.vue';

Vue.use(Router);
export default new Router({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : process.env.BASE_URL,
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/education',
      name: 'education',
      component: () => import('@/views/education/education.vue'),
    },
    {
      path: '/announcement',
      name: 'announcement',
      component: () => import('@/views/announcement/announcement-230131.vue'),
    },
    {
      path: '/privacyPolicy',
      name: 'privacyPolicy',
      component: () => import('@/views/privacy-policy/index.vue'),
    },
    {
      path: '/propaganda',
      name: 'propaganda',
      component: () => import('@/views/propaganda/index.vue'),
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/abnormal/404.vue'),
    },
  ],
});
