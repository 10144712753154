/*
 * @Author: ruiqiwu
 * @Date: 2023-02-27 17:11:23
 * @LastEditors: ruiqiwu
 * @LastEditTime: 2023-02-27 20:11:07
 * @FilePath: /cftxd/src/config/mta.config.js
 * @Description: mta 应用信息配置
 */

// 资金与数据部
export const mtaConfig = {
  sid: 500718782, // 必填，统计用的appid
  cid: 500718840, // 如果开启自定义事件，此项目为必填，否则不填
  fsystemCode: 'cftxd',
};
