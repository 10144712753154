import Vue from 'vue';

import { Alert, Button, Input, Message, MessageBox, Table, TableColumn, Pagination } from 'element-ui';
Vue.use(Input);
Vue.use(Button);
Vue.use(Alert);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.$ELEMENT = { size: 'medium' };
